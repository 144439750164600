<template>
  <div id="app">
<!--    Composant NavBar plus utilisé-->
    <NavBar/>
    <router-view/>
    <Footer/>
  </div>
</template>
<script>
  import NavBar from '@/components/NavBar.vue';
  import Footer from '@/components/Footer.vue';

  export default {
    name: 'App',
    components: {
      NavBar,
      Footer,
    },
  };
</script>

<style lang="scss">
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  #nav {
    padding: 30px;

    a {
      font-weight: bold;
      color: #2c3e50;

      &.router-link-exact-active {
        color: #42b983;
      }
    }
  }
  //@import "~bulma/sass/utilities/_all";
  // Set your colors
  $primary: rgba(233, 7, 0, 0.63);
  $primary-invert: white;// findColorInvert($primary);

  //$colors: (
  //        "white": ($white, $black),
  //        "black": ($black, $white),
  //        "light": ($light, $light-invert),
  //        "dark": ($dark, $dark-invert),
  //        "primary": ($primary, $primary-invert),
  //        "info": ($info, $info-invert),
  //        "success": ($success, $success-invert),
  //        "warning": ($warning, $warning-invert),
  //        "danger": ($danger, $danger-invert),
  //);
  $table-sticky-header-height: 600px;

  // Import Bulma and Buefy styles
  @import "~bulma";
  @import "~buefy/src/scss/buefy";
  html, body {
    height: calc(100% - 52px);
    background-color: #efefef;
  }

</style>
