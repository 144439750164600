// USER MUTATIONS
export const LOGIN_STARTED = 'LOGIN_STARTED';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_SUCEEDED = 'LOGIN_SUCEEDED';

export const LOGOUT_STARTED = 'LOGOUT_STARTED';
export const LOGOUT_FINISHED = 'LOGOUT_FINISHED';

// ETABS MUTATIONS
export const ESTABS_REQUEST = 'ESTABS_REQUEST';
export const ESTABS_SUCCESS = 'ESTABS_SUCCESS';
export const ESTABS_FAILURE = 'ESTABS_FAILURE';
