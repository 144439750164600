<template>
<div id="arbosListContainer" data-app>
  <div>
    <vs-navbar v-model="activeItem" class="nabarx" style="position:fixed" color="rgba(255,255,250,0.92)" active-text-color="#FE02BB">
      <div slot="title">
        <vs-navbar-title>
          <div><a href="index.html"><img src="@/assets/images/logos/logo_rose_tr.png" class="logo-navbar"></a></div>
        </vs-navbar-title>
      </div>
      <vs-navbar-item index="0">
        <a href="#portraitpb">Portrait porte-bonheur</a>
      </vs-navbar-item>
      <vs-navbar-item index="1">
        <a href="#consultation">Consultation</a>
      </vs-navbar-item>
      <vs-navbar-item index="2">
        <a href="#who-we-are">Qui sommes nous ?</a>
      </vs-navbar-item>
      <vs-navbar-item index="3">
        <a href="#temoignage">Témoignages</a>
      </vs-navbar-item>
      <vs-navbar-item index="4">
        <a href="#tarif-consultation">Tarifs</a>
      </vs-navbar-item>
      <vs-navbar-item index="5">
        <a href="#contact">Contact</a>
      </vs-navbar-item>
    </vs-navbar>
  </div>
  <vue-horizontal-list :items="homeSlider" :options="optionsHomeSlide" class="slide-show-home">
    <template v-slot:default="{item}">
      <div>
        <div class="Banner ptb-48 p-20 border-2" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }">
          <div class="flex-center">
            <div class="flex-column-align-center text-center">
              <h1 id="title-home">{{ item.title }}</h1>
              <p id="subtitle-home">{{ item.content }}</p>
              <div class="mt-24">
                <v-btn style="margin-top: 20px;" outlined color="#FEFFFF" :href="item.link">Découvrir</v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </vue-horizontal-list>
  <div id="wrap">
    <portraitpb v-if="activeItem == 0"></portraitpb>
    <consFormEvene hidden="true"></consFormEvene>
    <consultation v-if="activeItem == 1"></consultation>
    <information v-if="activeItem == 2"></information>
    <contact-avis v-if="activeItem == 3"></contact-avis>
    <price v-if="activeItem == 4"></price>
    <contact v-if="activeItem == 5"></contact>
  </div>
</div>
</template>
<script>

import { mapState, mapActions } from 'vuex';
import api from '@/api';
import 'material-icons/iconfont/material-icons.css';
import information from '@/components/homepage/information/Information';
import consFormEvene from '@/components/homepage/formation_evenement/cfe';
import contactAvis from '@/components/homepage/contact_avis/contactAvis';
import Consultation from '@/components/homepage/consultation/Consultation';
import portraitpb from '@/components/portraitpb/portraitpb';
import contact from '@/components/homepage/contact/Contact';
import price from '@/components/homepage/price/Price';

export default {
  name: 'arbos-list',
    components: {
        information,
        consFormEvene,
        contactAvis,
        Consultation,
      portraitpb,
      price,
      contact
    },
  data() {
    return {
      activeItem: 0,
      displayServies: 3,
      displayImpact: true,
      sheetImpact: false,
      aboutVisibility: false,
      commentDiderotVisibility: false,
      homeSlider: [
        {
          title: 'Portraits porte-bonheur',
          content: '',
          image: require('@/assets/images/slider/portrait_ppb.png'),
          link: '#portrait-porte-bonheur'
        },
        {
          title: 'Consultation',
          content: '',
          image: require('@/assets/images/slider/consultation.png'),
          link: '#ppb-love'
        },
        {
          title: 'Qui sommes nous ?',
          content: '',
          image: require('@/assets/images/slider/chiffre_porte_bonheur_2.png'),
          link: '#who-we-are'
        },
        {
          title: 'Témoignages',
          content: '',
          image: require('@/assets/images/slider/temoignage.png'),
          link: '#formation'
        },
        {
          title: 'Contact',
          content: '',
          image: require('@/assets/images/slider/contact.png'),
          link: '#temoignage'
        },
      ],
      optionsHomeSlide : {
        responsive: [{ size: 1 }],
        autoplay: {
          play: true,
          speed: 5000,
          repeat: true,
        },
      },
    };
  },
  computed: {
    ...mapState([
      'arbos',
      'loadingArbos',
      'error',
      'colors',
      'suivis',
      'valueSearchArbo',
    ]),
  },
  beforeMount() {
    // Adapter l'écran entre vue smartphone et pc
    if (document.body.clientWidth <= 500) {
      this.displayServies = 5;
    } else {
      this.displayServies = 2;
    }
    if (document.body.clientWidth <= 1200) {
      this.displayImpact = false;
    } else {
      this.displayImpact = true;
    }
  },
  mounted() {
    // this.loadArbos();
    // this.loadColors();
    // this.loadSuivis();
  },
  methods: {
    ...mapActions([
      'loadArbos',
      'loadArbosWithSearch',
      'loadColors',
      'loadSuivis',
      'updateSuivis',
    ]),
    updateAll() {
      this.loadArbos();
      this.updateSuivis();
      this.loadColors();
      this.suiviService += 1;
    },
    loadArbos() {
      // commit('searchLoadSuccess');
      // const url = `/api/graph`; // arbo
      return api.get('/api/graph');
    },
    // getmyDate(myDate) {
    //   const date = new Date(myDate);
    //   if (date.toDateString() === 'Invalid Date') {
    //     return myDate;
    //   }
    //   return date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + " " + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds()
    // },
    onceHandler() {
      console.log("passage");
      this.aboutVisibility = true;
    },
    onceHandlerDiderot() {
      console.log("passage 2");
      this.commentDiderotVisibility = true;
    },
    fetchEventsList() {
      console.log('event');
      // this.updateSuivis();
    },
    cancelAutoUpdate() {
      clearInterval(this.timer);
    },
  },
  created() {
    this.timer = setInterval(this.fetchEventsList, 80000);
    // let statut = document.getElementById('statutsuivi');
    // console.log(statut);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

@import './homePage.scss';
@import './../../assets/css/style.css';
@import './../../assets/css/animate.css';

</style>
