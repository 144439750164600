import { API_URL } from '@/helpers';
import axios from 'axios';

console.log('HOLA', API_URL);

const graphApi = axios.create({
  baseURL: API_URL,
});

// helper pour exclure les routes pour lesquelles exclure le refresh
const isRouteOk = ({ url }) => !url.endsWith('cflogin') && !url.endsWith('refresh');

// On ajoute l'interceptor a tout axios
// en vrai on creerait une instance d'axios (https://github.com/axios/axios#creating-an-instance) pour l'api qu'on importerait la ou on en a besoin
// https://github.com/axios/axios#interceptors pour doc sur les interceptors
graphApi.interceptors.request.use(
  (config) => {
    if (config.victorIsTesting) {
      console.log('victor is testing => request');
    }
    // si c'est pas login on ajoute le header d'auth
    if (isRouteOk(config)) {
      const token = window.localStorage.getItem('access_token');
      // eslint-disable-next-line
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);


// helpers pour l'interceptor
const refreshToken = (error) => {
  const refresh = window.localStorage.getItem('refresh_token');
  if (refresh) {
    return axios.post(`${API_URL}/auth/refresh`, {}, {
      headers: {
        authorization: `Bearer ${refresh}`,
    },
  });
  }
  return Promise.reject(error);
};

// Ici on ajoute un interceptor pour les reponses en error => != 2xx
graphApi.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log('on en est la');
    if (error.config.victorIsTesting) {
      console.log('fred is testing => response');
    }
    if (isRouteOk(error.config) && error.response.status === 401) {
      return refreshToken(error).then(({ data }) => {
        window.localStorage.setItem('access_token', data.access_token);
        // eslint-disable-next-line
        error.config.headers.authorization = `Bearer ${data.access_token}`;
        return axios.request({
          ...error.config,
          victorIsTesting: true,
        });
      });
    }
    return Promise.reject(error);
  },
);

export default graphApi;
