<template>
    <div id="login-form-container">
      <form>
        <section>
          <b-field label="Utilisateur">
            <b-input autofocus v-model="username"></b-input>
          </b-field>
          <b-field label="Mot de passe">
            <b-input @keyup.native.enter="submitLogin" type="password" v-model="password"></b-input>
          </b-field>
        </section>
        <b-button @click="submitLogin" class="is-primary submit-login" :loading="loggingIn">
          Connexion
        </b-button>
      </form>
    </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'login-form',
  data() {
    return {
      username: '',
      password: '',
    };
  },
  computed: {
    ...mapState('user', ['loggingIn']),
  },
  methods: {
    ...mapActions({
      login: 'user/login',
    }),
    submitLogin() {
      const { username, password } = this;
      this.login({
        username,
        password,
      }).then(() => {
        this.$router.push({ name: 'Home', path: '/' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  #login-form-container {
    background-color: #fdfdfd;
    padding: 30px;
    // padding-top: 12px;
    max-width: 450px;
    min-width: 350px;
    width: 80%;
    margin: 0 auto;
    .submit-login {
      margin-top: 14px;
    }
  }
</style>
