import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import Home from '../views/Home.vue';
import Login from '../components/LoginForm/LoginForm.vue';
// import ArboPage from '../views/ArboPage.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter(to, from, next) {
      const { loggedIn } = store.state.user;
      if (loggedIn === false) {
        next('/login');
      } else {
        next();
      }
    },
  },
  {//Rediriger l'user sur la page d'accueil dès qu'il raffraichit
    path: '/*',
    name: 'Home',
    component: Home,
    beforeEnter(to, from, next) {
      next();
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter(to, from, next) {
      const { loggedIn } = store.state.user;
      if (loggedIn) {
        next('/');
      } else {
        next();
      }
    },
  },
  {
    path: '/arbo/:arboName',
    name: 'Arbo',
    props: true,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
