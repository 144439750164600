<template>
  <div class="home">
    <HomePage></HomePage>
  </div>
</template>

<script>
// @ is an alias to /src
// import store from '@/store';
import { mapActions } from 'vuex';
import HomePage from '@/components/homepage/homePage.vue';

export default {
  name: 'Home',
  components: {
    HomePage,
  },
  computed: {
    arbos() {
      return this.$store.state.arbos;
    },
  },
  methods: {
    ...mapActions(['loadArbos']),
  },
};
</script>
