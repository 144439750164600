<template>
  <section id="contact" class="grey">
    <div class="title center">
      <h1 class="title-section">Contact</h1>
      <hr>
    </div>
    <section class="grey">
      <div v-if="window.width > 600" class="img-side img-right col-md-6 col-sm-4">
        <div id="map">
          <iframe style="width: -webkit-fill-available;height: 88%;" src="https://maps.google.fr/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(le%20club%20des%205%20sens)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
        </div>
      </div>
        <div class="container who-we-are">
          <div class="col-md-5 col-sm-8">

            <div class="title" style=  "text-align: left;">
              <h1 style="margin-bottom: 15px;">Prendre rendez-vous</h1>
              <h3>par téléphone</h3>
              <p>
                01 42 04 93 24
                <br>06 44 04 25 16
              </p>
            </div>
            <div class="title" style="text-align: left;">
              <h3>Par mail</h3>
              <p>deschiffresetdesetoiles@gmail.com</p>
            </div>
            <div class="title" style="text-align: left;">
              <h3>Comment venir au cabinet</h3>
              <p>
                51 boulevard Henri Sellier
                <br>92150 SURESNES
                <br>Arrêt de Tramway (T2) Suresnes Longchamp
                <br>Arrêt de train Suresnes-Mont valérien
                <br>Bus N° 244 – 241 - 93
              </p>
            </div>

          </div>
        </div>
    </section>
  </section>

</template>

<script>

export default {
  name: 'contact',
  data() {
    return {
      window: {
        width: 0,
        height: 0
      },
    }
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}

</script>
