import Vue from 'vue';
import Vuex from 'vuex';
import api from '@/api';
import user from './user.store';
import Vuetify from 'vuetify'

Vue.use(Vuex);
Vue.use(Vuetify);

export default new Vuex.Store({
  state: {
    loadingColonne: false,
    loadingArbos: false,
  },
  mutations: {

    suivisLoadSuccess(state, datas) {
      state.suivis = datas;
      state.error = null;
    },
    searchLoadSuccess(state, datas) {
      // console.log('load');
      const temp = state.arbos;
      // eslint-disable-next-line
      for (let dat of datas) {
        // eslint-disable-next-line
        for (let i = 0; i < temp.length; i += 1) {
          if (dat.name === temp[i].name) {
            temp[i].search = 'room';
          }
        }
      }
      state.arbos = null;
      state.arbos = temp;
      state.loadingArbos = false;
      state.error = null;
    },
  },
  actions: {
    // On ne modifie pas le state direct dans les actions, uniquement dans les mutations.
    loadColors({ commit }) {
      return api.get('/api/noeud')
        .then(({ data }) => {
          const datas = {
            colors: [],
            nmicon: [],
            types: [],
            lbc: [],
          };
          for (let i = 0; i < data.length; i += 1) {
            datas.colors.push(data[i].colors);
            datas.types.push(data[i].types);
            datas.lbc.push(data[i].lbc);
            datas.nmicon.push(data[i].nmicon);
            commit('colorsLoadSuccess', datas);
          }
          // console.log('couleure récup' + datas.types + datas.colors);
        })
        .catch((e) => Promise.reject(e));
    },
  },
  modules: {
    user,
  },
});
