<template>
  <div id="tarif-consultation" class="grey">
    <div class="container">
      <div class="container who-we-are" v-observe-visibility="{ callback: consultationHandler, once: true , throttle: 1500}" :class="{ 'visible animated fadeInLeft': consultationVisibility, 'invisible': !consultationVisibility }">
        <div class="title center">
          <h1>Tarifs et consultations</h1>
          <hr>
        </div>
        <h2>Règlement PayPal</h2>
        <p><i>Cochez ce que vous voulez puis cliquez sur le bouton ci-dessous</i></p>
        <p><i>Une fois le paiement effectué, vous recevrez un mail de validation</i></p>
        <PayPal
            :amount="amountPayPal"
            currency="EUR"
            :client="credentials"
            :items="myItems"
            env="production" :hidden="selectedConsultation.length == 0">
        </PayPal>
        <p v-if="selectedConsultation.length != 0">
          Total : {{ amountPayPal }} €
          <br>On ne peut pas enregistrer le solde (pas de compte pour l'enregistrer)
        </p>

        <v-container fill-height fluid>
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="6" xl="6">
              <div class="title center">
                <p class="title-section pink-color">Portrait Porte-Bonheur : Les chiffres clés de votre destinée ENFANT-ADULTE Numérologie</p>
              </div>
              <v-checkbox
                  v-model="selectedConsultation" value="50" color="orange" hide-details
                  @change="onInput('Portrait Porte-Bonheur : Les chiffres clés de votre destinée ENFANT-ADULTE : Version digitale')">
                <template v-slot:label>
                  <div>
                    <h3>Version digitale : 50 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="65" color="orange" hide-details
                  @change="onInput('Portrait porte-bonheur : Les chiffres clés de votre destinée ENFANT-ADULTE : Album imprimé en papier cartonné et vélin')">
                <template v-slot:label>
                  <div>
                    <h3>Album imprimé en papier cartonné et vélin : 65 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="10" color="orange" hide-details
                  @change="onInput('Portrait Porte-Bonheur : Les chiffres clés de votre destinée ENFANT-ADULTE : Album imprimé supplémentaire')">
                <template v-slot:label>
                  <div>
                    <h3>Album imprimé supplémentaire : 10 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="90" color="orange" hide-details
                  @change="onInput('Portrait Porte-Bonheur : Les chiffres clés de votre destinée ENFANT-ADULTE : 2 commandes différentes en version digitale')">
                <template v-slot:label>
                  <div>
                    <h3>2 commandes différentes en version digitale : 90 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="120" color="orange" hide-details
                  @change="onInput('2 commandes différentes en album')">
                <template v-slot:label>
                  <div>
                    <h3>2 commandes différentes en album : 120 euros</h3>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="8" md="6" lg="6" xl="6">
              <div class="title center">
                <p class="title-section pink2-color">Portrait porte-bonheur : Des chiffres et des étoiles ENFANT-ADULTE</p>
              </div>
              <v-checkbox
                  v-model="selectedConsultation" value="65" color="orange" hide-details
                  @change="onInput('Portrait porte-bonheur : version digitale')">
                <template v-slot:label>
                  <div>
                    <h3>Version digitale : 65 €</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="80" color="orange" hide-details
                  @change="onInput('Portrait porte-bonheur : Album imprimé en papier cartonné et vélin : 75 euros')">
                <template v-slot:label>
                  <div>
                    <h3>Album imprimé en papier cartonné et vélin : 80 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="20" color="orange" hide-details
                  @change="onInput('Portrait porte-bonheur : Album imprimé supplémentaire : 20 euros \n')">
                <template v-slot:label>
                  <div>
                    <h3>Album imprimé supplémentaire : 20 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="110" color="orange" hide-details
                  @change="onInput('Portrait porte-bonheur : 2 commandes différentes en version digitale : 110 euros')">
                <template v-slot:label>
                  <div>
                    <h3>2 commandes différentes en version digitale : 110 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="140" color="orange" hide-details
                  @change="onInput('Portrait porte-bonheur : 2 commandes différentes en album (astro-numéro-5sens) : 140 euros\n')">
                <template v-slot:label>
                  <div>
                    <h3>2 commandes différentes en album (astro-numéro-5sens) : 140 euros</h3>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="8" md="6" lg="6" xl="6">
              <div class="title center">
                <p class="title-section fushua-color">Portrait Porte-Bonheur Love</p>
              </div>
              <v-checkbox
                  v-model="selectedConsultation" value="110" color="orange" hide-details
                  @change="onInput('Portrait Porte-Bonheur COUPLE : Version digitale')">
                <template v-slot:label>
                  <div>
                    <h3>Version digitale : 110 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="130" color="orange" hide-details
                  @change="onInput('Portrait Porte-Bonheur COUPLE : Album')">
                <template v-slot:label>
                  <div>
                    <h3>Album : 130 euros</h3>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="8" md="6" lg="6" xl="6">
              <div class="title center">
                <p class="title-section blue-color">Consultation au cabinet pour un Portrait Porte-Bonheur ENFANT-ADULTE</p>
              </div>
              <v-checkbox
                  v-model="selectedConsultation" value="150" color="orange" hide-details
                  @change="onInput('Consultation au cabinet pour un Portrait Porte-Bonheur ENFANT-ADULTE : remise du portrait en version papier et digitale')">
                <template v-slot:label>
                  <div>
                    <h3>1h30 avec remise du portrait en version papier et digitale : 150 euros</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="80" color="orange" hide-details
                  @change="onInput('Consultation communication bienveillante')">
                <template v-slot:label>
                  <div>
                    <h3>Consultation communication bienveillant : 80 euros</h3>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" sm="8" md="6" lg="6" xl="6">
              <div class="title center">
                <p class="title-section">Formation NUMEROLOGIE</p>
              </div>
              <v-checkbox
                  v-model="selectedConsultation" value="210" color="orange" hide-details
                  @change="onInput('Formation NUMEROLOGIE : 1 jour et demi')">
                <template v-slot:label>
                  <div>
                    <h3>1 jour et demi : 210 euros par participant</h3>
                  </div>
                </template>
              </v-checkbox>
              <v-checkbox
                  v-model="selectedConsultation" value="85" color="orange" hide-details
                  @change="onInput('Formation NUMEROLOGIE : 1 matinée')">
                <template v-slot:label>
                  <div>
                    <h3>1 matinée : 85 euros par participant</h3>
                  </div>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
        </v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <p>
              Comment procéder :
              <br>
              Sélectionnez le produit desiré, procédez au paiement via Paypal puis veuillez nous envoyer un email avec le numéro de votre commande  en objet
              (vous pouvez également nous  transférer directement l’email de Paypal  avec le numéro de commande à l’adresse suivante deschiffresetdesetoiles@gmail.com
              et en fonction des portraits/consultation choisis, merci de bien vouloir nous indiquer :

              <br>Portrait Enfant/Adulte:
              Nom
              Prénom
              Date de naissance
              Heure de naissance
              Lieu de naissance (ville + code postal)
              Photos éventuelles

              <br>Portrait LOVE :
              Pour chaque membre :
              Nom
              Prénom
              Date de naissance
              Heure de naissance
              Lieu de naissance (ville + code postal)
              Photos éventuelles

              <br><br>Consultation
              <br>Les horaires qui vous conviennent le mieux. Nous vous contacterons pour convenir d’un rendez-vous dans les plus brefs délais.
              Nous proposons des consultations par téléphone, visioconférence ou au cabinet du Lundi au Vendredi de 9h a 17h et le Samedi de 9h a 12h (horaires adaptables).
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="12" xl="12">
            <p>Note :
              <br>Les albums imprimés comprennent la version digitale
              <br>Les Portraits Porte-Bonheur sont personalisés et sont donc non échangeables et non remboursables
            </p>
            <p><br>Les frais de port sont offerts sur toute la France</p>
          </v-col>
        </v-row>
        <!--                <a href="http://youtube.com" target="_blank"><v-icon large>mdi-youtube</v-icon></a>-->
        <a href="http://youtube.com" target="_blank"><v-icon large>mdi-instagram</v-icon></a>
      </div>
    </div>
  </div>
</template>


<script>

import PayPal from 'vue-paypal-checkout';

export default {
  name: 'price',
  data() {
    return {
      consultationVisibility: false,
      selectedConsultation: [],
      reducer: (accumulator, currentValue) => parseInt(accumulator) + parseInt(currentValue),
      credentials: {
        // sandbox: 'AZMHIYZ2htPryBhTetiWCTp8CfbswSfI5qYqu80Cw2J3Igw593I1FP8rK6NcsaopUkoZPfXwr7Aqk4zo',
        production: 'AdD5cycYVFZ1YmoJKivUcWlriK5tyw1RACDYHu5bfHoXBPO4YTsf-lhBTeFyYN5LFDoBkMNY0GStLpzL'
      },
      myItems: [],
      amountPayPal: "0",
    }
  },
  methods: {
    consultationHandler() {
      this.consultationVisibility = true;
    },
    addConsultation() {
      return 1;
    },
    onInput(label) {
      this.myItems = [];
      this.amountPayPal = "0";
      if (this.selectedConsultation.length !== 0) {
        for (const argument of this.selectedConsultation) {
          this.myItems.push( {
                "name": label,
                "description": "",
                "quantity": "1",
                "price": argument.toString(),
                "currency": "EUR"
              }
          );
          this.amountPayPal = (parseInt(this.amountPayPal) + parseInt(argument)).toString();
        }
      }
    }
  },
  components: {
    PayPal
  }
}

</script>

<style lang="scss">
@import '../../color';
</style>
