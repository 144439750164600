import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue';
import 'vuetify/src/styles/styles.sass';
import 'vuetify/src/styles/main.sass';
import 'vuetify/dist/vuetify.min.css';
import Buefy from 'buefy';
import App from './App.vue';
import router from './router';
import store from './store';
import '@mdi/font/css/materialdesignicons.css';
import vuetify from './plugins/vuetify';
import observeVisibiliy from 'vue-observe-visibility';
import VueHorizontalList from 'vue-horizontal-list';
import VueHorizontalListAutoscroll from 'vue-horizontal-list-autoscroll';
import Vuesax from 'vuesax';
import 'vuesax/dist/vuesax.css';

Vue.config.productionTip = false;
Vue.use(Buefy);
Vue.use(observeVisibiliy);
Vue.use(VueHorizontalList);
Vue.use(Vuesax);
Vue.use(VueHorizontalListAutoscroll);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
