import { ToastProgrammatic as Toast } from 'buefy';
import api from '@/api';
import {
  LOGIN_FAILED,
  LOGIN_STARTED,
  LOGIN_SUCEEDED,
  LOGOUT_FINISHED,
  LOGOUT_STARTED,
} from './mutation_types';

export default {
  namespaced: true,
  state: {
    loggingIn: false,
    loggingOut: false,
    user() {
      return window.localStorage.getItem('user');
    },
    err: null,
  },
  mutations: {
    [LOGIN_STARTED](state) {
      state.loggingIn = true;
    },
    [LOGIN_SUCEEDED](state, user) {
      state.loggedIn = true;
      state.user = user;
      state.loggingIn = false;
    },
    [LOGIN_FAILED](state, err) {
      state.err = err;
      state.loggingIn = false;
    },
    [LOGOUT_STARTED](state) {
      state.loggingOut = true;
    },
    [LOGOUT_FINISHED](state) {
      state.user = null;
      state.loggedIn = false;
      state.loggingOut = false;
    },
  },
  actions: {
    login({ commit }, { username, password }) {
      commit(LOGIN_STARTED);
      /* eslint-disable no-template-curly-in-string */
      return api.post('/auth/login', {
        username,
        password,
      })
        .then((r) => {
          Toast.open({
            message: 'Vous êtes maintenant connecté',
            type: 'is-success',
          });
          console.log(r.data);
          // eslint-disable-next-line
          const { access_token, refresh_token, nmuser, okadmin, grpuser } = r.data;
          // setting local storage for axios
          window.localStorage.setItem('access_token', access_token);
          window.localStorage.setItem('refresh_token', refresh_token);
          window.localStorage.setItem('user', nmuser);
          // window.localStorage.setItem('okadmin', okadmin);
          // window.localStorage.setItem('grpuser', grpuser);
          commit(LOGIN_SUCEEDED, r.data);
        })
        .catch((e) => {
          // Dynamiser tout ca
          Toast.open({
            message: 'Les identifiants sont invalides',
            type: 'is-danger',
          });
          commit(LOGIN_FAILED, e);
          return Promise.reject(e);
        });
    },
    logout({ commit }) {
      commit(LOGOUT_STARTED);
      // remove from storage
      window.localStorage.removeItem('access_token');
      window.localStorage.removeItem('refresh_token');
      window.localStorage.removeItem('user');
      // window.localStorage.removeItem('okadmin');
      commit(LOGOUT_FINISHED);
    },
  },
  modules: {
  },
};
