<template>
  <section class="portrait grey" id="communicationbv">
    <div class="title center">
      <h3 class="title-section">Après l’élaboration de votre Portrait Porte-Bonheur, nous vous proposons de prendre rendez-vous pour :</h3>
      <hr>
    </div>
    <div class="container">
      <p style="color:black">Une explication approfondie de votre Portrait Porte-Bonheur</p>
      <p>
        Pour répondre à vos questions et vous apporter une explication plus approfondie de tous les points forts de votre thème.
        <br>Pour faire ressortir les axes de progressions que vous souhaiteriez améliorer avec nous.
      </p>
      <p style="color:black; margin-top: 0.5em;">Une séance de communication bienveillante adaptée à votre profil numérologique et astrologique</p>
      <p>
        Pour vous aider à améliorer vos échanges avec vos proches
        <br>Pour trouver ensemble les solutions et les approches qui vous permettront de franchir des étapes (perte de confiance, conflit, angoisse…)
        à travers une communication positive personnalisée pour mieux cerner vos attentes et y faire face de manière constructive et bénéfique pour vous et votre entourage.
      </p>
      <img style="margin: 1em;"
           :src="require('@/assets/images/portrait/apres_elabo_pb.png')"
      >
    </div>
  </section>
</template>


<script>

export default {
  name: 'Consultation',
}

</script>

<style lang="scss">
@import '../../color';
</style>
