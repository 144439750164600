<template>
  <div>
    <!-- Home Section -->

    <!-- Intro Section -->
    <section id="who-we-are" class="grey">
      <div class="container" >
<!--        v-observe-visibility="{ callback: whoWeAreHandler, once: true , throttle: 1500}" :class="{ 'visible animated fadeInLeft': whoWeAreVisibility, 'invisible': !whoWeAreVisibility }"-->
        <div class="container who-we-are" >
          <div class="title center">
            <h1>Qui sommes nous ?</h1>
            <hr>
          </div>
          <p style="text-align: justify">
            Certaines intuitions, vocations ou changements d'orientations, ne demandent qu'à s'exprimer afin de passer à une nouvelle étape de notre vie et favoriser notre bien-être. Néanmoins, ils peinent parfois à se matérialiser en raison des doutes et des inquiétudes qu'ils peuvent engendrer.
            Si chacun est maître de son destin, une approche communicationnelle et/ou divinatoire peut permettre de mieux se connaître et ainsi de trouver des éléments de réponses à nos questions.
            <br>Aussi, Des Chiffres et des Etoiles , cabinet de coaching/conseil en développement personnel, vous propose une approche inédite et sur-mesure fondée sur 2 principales expertises complémentaires :
          </p>
          <h2><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Le coaching en communication</h2>
          <p>
            par le prisme de la Sophrologie et de la Communication Bienveillante pour apprendre à mieux gérer son stress, ses émotions et à améliorer ses échanges relationnels avec son entourage familial, amical et professionnel.
          </p>
          <h2><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Le coaching divinatoire</h2>
          <p>
            qui s'appuie sur la Numérologie et l’Astrologie afin de mieux comprendre les grands traits de votre personnalité, vos ambitions et défis de vie et de connaître les moments propices pour saisir les meilleures opportunités qui s'offrent à vous."
          </p>
        </div>
      </div>
    </section>
    <section>
      <v-container class="container">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4" xl="4">

            <div id="team" class="title center">
              <h1 style="margin-top: 0.5em;">L'équipe</h1>
              <hr>
            </div>
            <h2>Un duo : Laurence et Catherine</h2>
            <p style="text-align: justify">2 coachs passionnées par les arts divinatoires et la communication pour l’amélioration du bien-être !</p>
            <h2>Côté astrologie</h2>
            <p style="text-align: justify">
              L’une est Bélier, l’autre Sagittaire, 2 signes de Feu positifs et énergiques, associés à des ascendants Air et Eau pour s’adapter à chaque personnalité !
            </p>
            <h2>Coté numérologie</h2>
            <p style="text-align: justify">
              L’'une est 3, favorisant la facilité d’expression, les échanges constructifs et la créativité afin de mettre en évidence vos plus belles qualités !
              L’autre est 9, indiquant une écoute attentive, de l’empathie, une grande ouverture d’esprit pour vous conseiller sur vos axes de progression !
              <br><br>Avec le chiffre 6 en commun dans leur but de vie, Laurence et Catherine sont 2 coachs attentives et dynamiques, mettant à votre service leur expertise et leur expérience pour vous guider, vous orienter, vous éclairer sur vos talents !
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4" xl="4">
            <!--                        <v-img :src="require('@/assets/images/ourteam2.png')" style="display: inline-grid;" alt="our team" contain></v-img>-->
            <img :src="require('@/assets/images/ourteam.jpeg')" style="display: inline-grid; padding: 1em;" alt="our team" contain/>
          </v-col>
          <v-col style="display: flow-root;" cols="12" sm="12" md="12" lg="4" xl="4" class="container team">

            <div class="title center">
              <h1>Notre mission</h1>
              <hr>
            </div>
            <p style="text-align: justify">
              Des chiffres et des étoiles est un cabinet de coaching en développement personnel dont la vocation est de vous aider à améliorer votre bien-être par une approche sur-mesure reposant sur 2 expertises complémentaires :
              <br>Dans un premier temps, la Numérologie et l’Astrologie par la réalisation d’un Portrait Porte-Bonheur qui vous permettra de mieux comprendre les grands traits de votre personnalité, vos talents et vos aspirations.
              <br>Dans un second temps, la Communication Constructive et Positive par le prisme de la Sophrologie et de la Communication Bienveillante pour apprendre, après une explication approfondie de votre Portrait Porte-Bonheur et en fonction de celui-ci, à mieux gérer votre stress,
              vos émotions et à améliorer vos échanges relationnels avec votre entourage familial, amical et professionnel.
            </p>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>

export default {
  name: 'information',
  data() {
    return {

      whoWeAreVisibility: false,
    }
  },
  methods: {
    whoWeAreHandler() {
      this.whoWeAreVisibility = true;
    },
  }
}


</script>

<style lang="scss">
@import "Information.scss";
</style>
