<template>
  <div id="portraitpb" class="grey">
    <section class="portrait grey" id="portrait-porte-bonheur">
      <div class="title center">
        <h1 class="title-section">Portraits porte-bonheur</h1>
        <hr>
      </div>
      <v-container fill-height fluid>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <h1 class="title-section fushua-color">Qu’est-ce qu’un Portrait Porte-Bonheur ?</h1>
            <p style="text-align: justify">
              Le Portrait Porte-Bonheur – Des Chiffres et des Etoiles se fonde d’une part, sur l’étude de votre thème astral qui correspond à la position des planètes le jour et à
              l’heure de votre naissance et d’autre part, sur l’analyse de votre profil numérologique réalisé à partir de votre date de naissance et de vos nom et prénom.
              L’approche complémentaire de ces deux disciplines vous apportera une vision précise des talents innés que vous pourriez optimiser et déployer,
              de vos aspirations profondes et de vos objectifs pour les principaux domaines de votre vie.
              <br>Ce Portrait Porte-Bonheur repose sur 4 piliers : vos Bonnes Etoiles en astrologie, vos Chiffres Porte-Bonheur en numérologie,
              vos Lettres de Noblesse (influence des lettres de vos nom et prénom sur votre personnalité), L’Eveil des 5 sens pour vous informer sur vos centres d’intérêt.

            </p>
            <img
                :src="require('@/assets/images/portrait/qu_est_pb.png')"
                style="height:40%;padding-top: 1em;"
            >
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <h1 class="title-section fushua-color">4 bonnes raisons de commander un Portrait Porte-Bonheur</h1>
            <h2>Pour soi ou pour ses proches</h2>
            <p style="text-align: center">
              <span class="fushua-color">Pour qui ?</span> Pour vous et/ou vos proches
              <br><span class="fushua-color">Pour quelle(s) occasion(s) ?</span> Une naissance, un baptême, un anniversaire, Noël, un pacs, un mariage ou toute autre bonne raison pour faire plaisir à vos proches ou à vous-même !
            </p>
            <p class="fushua-color" style="margin-top: 3%;">4 bonnes raisons de commander un Portrait Porte-Bonheur :</p>
            <p>Le Portrait Porte-Bonheur est…</p>
            <p class="fushua-color" style="margin-top: 3%;">1/Personnalisé</p>
            <p>réalisé sur-mesure en fonction du profil astrologique et numérologique de la personne à laquelle il est destiné.*</p>
            <p class="fushua-color" style="margin-top: 3%;">2/Original</p>
            <p>c’est un outil de développement personnel atypique et recherché !</p>
            <p class="fushua-color" style="margin-top: 3%;">3/Atemporel</p>
            <p>il constitue une feuille de route à laquelle se reporter tout au long de la vie.</p>
            <p class="fushua-color" style="margin-top: 3%;">4/Élégant</p>
            <p>sur papier vélin et cartonné ivoire, le tout relié par un ruban raffiné.</p>
          </v-col>
        </v-row>
      </v-container>
      <v-container fill-height fluid>
        <v-row>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <h1 class="title-section pink-color">PORTRAIT PORTE-BONHEUR - LES CHIFFRES CLES DE VOTRE DESTINEE Numérologie</h1>
            <h2>Enfant-Adulte</h2>
            <!--                        <hr>-->
            <p>
              Pour commander un album Portrait Porte-Bonheur, merci de nous faire parvenir pour l’enfant ou l’adulte concerné par mail à deschiffresetdesetoiles@gmail.com
              <br>Date, lieu, heure de naissance + nom de naissance et prénom
              <br><br>*Si vous le souhaitez, pour personnaliser l’album, vous pouvez envoyer des photos du bébé, de l’enfant, de l’adulte, des photos du couple, de votre famille (parents et enfants).
              <br>*Les photos seront supprimées de nos données, après envoi de l’album selon le respect du RGPD.
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <img
                :src="imgPortrait[0]"
                aspect-ratio="1"
                style="max-height:700px;"
            >
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <h1 class="title-section fushua-color" id="ppb-love">PORTRAIT PORTE-BONHEUR LOVE - COUPLE</h1>
            <h2>Astrologie - Numérologie - Eveil des 5 sens</h2>
            <h2>Spécial Couple</h2>
            <!--                        <hr>-->
            <p>
              se fonde sur l’étude de la compatibilité des thèmes astraux et numérologiques des membres du couple auquel il est destiné.
              Il est réalisé à partir de leurs dates de naissance et de leurs noms et prénoms. L’approche complémentaire de ces deux disciplines vous apportera une
              vision précise des points forts de la relation, des attentes des conjoints l’un envers l’autre et vis-à-vis de la relation et de ce que chacun peut apporter à l’autre.
              Pour commander un album Portrait Porte-Bonheur LOVE, merci de nous faire parvenir pour le couple concerné par mail à deschiffresetdesetoiles@gmail.com
              <br>Date, lieu, heure de naissance + nom de naissance et prénom de chacun
              <br><br>*Si vous le souhaitez, pour personnaliser l’album, vous pouvez envoyer des photos.
              <br>Ces photos seront supprimées de nos données, après envoi de l’album selon le respect du RGPD.
            </p>
          </v-col>
          <v-col cols="12" sm="12" md="6" lg="6" xl="6">
            <!--                      Custom css pour réadapter-->
            <img id="img-pb-couple"
                 :src="imgPortrait[1]"
            >
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>


<script>


export default {
  name: 'portraitpb',
  data() {
    return {
      imgPortrait: [require('@/assets/images/portrait/pb_enfants_adultes.png'),
        require('@/assets/images/portrait/pb_love.png'),
      ],
      optionsportraitSlide : {
        responsive: [{ size: 1 }],
        autoplay: {
          // play: true,
          // speed: 5000,
          // repeat: true,
        },
      },
    }
  },
}

</script>
<style lang="scss">
@import '../color';
@import "portraitpb";
</style>
