<template>
  <div>
    <!--        :style="{ backgroundImage: 'url(' + require('@/assets/images/consultation.jpg') + ')' }"-->
    <section id="formation" class="img-event">
      <img :src="require('@/assets/images/consultation.jpg')" style="background-size: cover; max-height: 300px; margin-top: 1em">
      <div class="title center">
        <h1 class="title-section">Nos formations</h1>
        <hr>
      </div>
      <div>
      </div>
      <!--            <div class="container" v-observe-visibility="{ callback: formationHandler, once: true , throttle: 1500}" :class="{ 'visible animated fadeInLeft': formationVisibility, 'invisible': !formationVisibility }">-->
      <div class="container">
        <v-card style="padding-bottom: 1em;" class="formation">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <section>
                <!--                        <img class="img-container" src="@/assets/images/test.png">-->
                <h1 class="fushua-color" style="font-size: 20px;">Introduction à la numérologie</h1>
                <h4>3h30 - 85 €</h4>
                <v-col class="formation-info">
                  <h1>
                    La Numérologie est un outil majeur pour identifier rapidement vos points forts, améliorer la connaissance de soi,
                    optimiser vos performances et trouver la voie de la réussite.
                  </h1>
                </v-col>
                <v-col class="formation-info">
                  <h2>Pour qui ?</h2>
                  <h4>
                    Les entreprises et les particuliers
                  </h4>
                </v-col>
                <v-col class="formation-info">
                  <h2>Pour quoi ?</h2>
                  <h4>
                    Apporter un éclairage sur vos aptitudes et compétences,
                    <br>Découvrir une méthode pour connaître les perspectives qui s’offrent à vous et saisir les meilleures opportunités qui contribueront à votre épanouissement personnel, relationnel et professionnel.
                  </h4>
                </v-col>
                <v-col class="formation-info">
                  <h3>Comment ?</h3>
                  <h2>Une initiation en 2 étapes :</h2>
                  <hr>
                  <h2>1. Les chiffres clés de la Numérologie</h2>
                  <h4>
                    Cette première étape consiste à comprendre : les principales facettes de votre personnalité à l'aide du chemin de vie et de l'ascendant, les caractéristiques positives que révèlent votre jour de naissance, l’initiale de votre nom et  votre prénom.
                  </h4>
                  <h3>2. L’étude de grandes personnalités</h3>
                  <h4>
                    L'analyse de la numérologie de personnes célèbres permet d’illustrer la signification des nombres composant le portrait numérologique de chacun.
                  </h4>
                </v-col>
              </section>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <img :src="require('@/assets/images/formation/influence_nombre_lettre.png')" height="auto">
            </v-col>
          </v-row>
        </v-card>
      </div>
      <!--            <div class="container" v-observe-visibility="{ callback: formationHandler, once: true , throttle: 1500}" :class="{ 'visible animated fadeInLeft': formationVisibility, 'invisible': !formationVisibility }">-->
      <div class="container">
        <v-card style="padding-bottom: 1em;" class="formation">
          <v-row>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <!--                        <img class="img-container" src="@/assets/images/test.png">-->
              <h1 class="fushua-color" style="font-size: 20px;">Initiation à la numérologie</h1>
              <h1 class="fushua-color">Influence des nombres et des lettres<br>chemin de vie, jour de naissance, prénoms et nom</h1>
              <h4>1 journée et demie - 210 €</h4>
              <v-col class="formation-info">
                <h2>Pour qui ?</h2>
                <h4>
                  Les entreprises et les particuliers
                </h4>
              </v-col>
              <v-col class="formation-info">
                <h2>Pour quoi ?</h2>
                <h4>
                  Connaître les points forts de son prénom et de son nom de famille, (une consultante peut encore hésiter entre 2 prénoms pour son bébé ou sur le choix d’un troisième prénom).
                  <br>Mieux se connaître pour comprendre et s’adapter plus aisément à son entourage personnel et professionnel.
                  <br>Développer une ouverture d’esprit sur ses proches qui ont un profil numérologique différent de soi et s’adapter pour mieux les cerner.
                </h4>
              </v-col>
              <v-col class="formation-info">
                <h1>Les grands fondamentaux de la Numérologie</h1>
                Etude du chemin de vie et ascendant, de vos chiffres clés : quintessence, excentricité, but….
                <ul class="li-formation">
                  <li><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Découvrir l’influence de votre jour de naissance</li>
                  <li><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Découvrir les points forts de votre prénom (miroir de votre personnalité) et vos aspirations</li>
                  <li><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Signification de l’initiale de votre prénom, une facette importante reflétant vos idéaux et vos désirs profonds</li>
                  <li><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Découvrir les valeurs et les atouts de votre nom de famille, votre nombre héréditaire, vos dons innés</li>
                  <li><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Signification de l’initiale de votre nom de famille, une autre facette inhérente à votre personnalité</li>
                  <li><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Synthèse du prénom + nom soit le nombre d’expression pour étudier votre portrait d’une manière plus complete. L’étude de grandes personnalités</li>
                  <li><v-icon style="color: #FE02BB; padding-bottom: 5px;">star_rate</v-icon>Etude de prénoms célèbres.</li>
                </ul>
              </v-col>
            </v-col>
            <v-col cols="12" sm="12" md="6" lg="6" xl="6">
              <img :src="require('@/assets/images/formation/introduction_numerologie.png')" max-height="450px" height="auto">
            </v-col>
          </v-row>
        </v-card>
      </div>
    </section>
    <div>
    </div>
    <!--        :style="{ backgroundImage: 'url(' + require('@/assets/images/tarot.jpg') + ')' }"-->
    <section class="evenementiel img-event" id="event" v-show="false">
      <div class="title center">
        <h1 class="title-section">Événementiel</h1>
        <hr>
      </div>
      <div>
        <img :src="require('@/assets/images/evenementiel.jpg')" style="background-size: cover; max-height: 300px; width: auto;">
      </div>
      <div class="container ">
        <v-row>
          <v-col cols="12" sm="12" md="12" lg="4" xl="4" >
            <v-card align="center" elevation="10" class="v-card-resize" shaped>
              <section>
                <div class="event">
                  <h1>Introduction à la numérologie</h1>
                  <h4>1h45 - 2h00 <i>(tarif : Nous consulter)</i></h4>
                  <h5><i>Destinée aux entreprises</i></h5>
                  <h3>Objectif de la conférence</h3>
                  <h4>
                    - Apporter un éclairage sur vos aptitudes et compétences,
                    <br>- Découvrir une méthode pour connaître les perspectives qui s’offrent à vous et saisir les meilleures opportunités qui contribueront à votre épanouissement personnel, relationnel et professionnel.
                  </h4>
                  <h2>Une conférence en 2 étapes :</h2>
                  <h3>1. Les chiffres clés de la Numérologie</h3>
                  <h4>
                    Cette première étape consiste à comprendre :
                    <br>1) les principales facettes de votre personnalité à l'aide du chemin de vie et de l'ascendant,
                    <br>2) les caractéristiques positives que révèlent votre jour de naissance,
                    <br>3) l’initiale de vos nom et prénom.
                  </h4>
                  <h2>2. L’étude de grandes personnalités</h2>
                  <p>
                    L'analyse de la numérologie de personnes célèbres permet d’illustrer la signification des nombres composant le portrait numérologique de chacun.
                  </p>
                </div>
              </section>
            </v-card>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="4" xl="4" class="reduce-opacity">
            <v-card elevation="10" height="auto" class="v-card-resize" shaped >
              <section>
                <div class="event">
                  <h4 style="margin-top: 2%"><i>Autour des 5 sens</i></h4>
                  <h1>Initiation à la sophrologie au coeur de la nature</h1>
                  <h4>1h30 <i>(tarif : Nous consulter)</i></h4>
                  <h5><i>Destinée aux entreprises</i></h5>
                  <h3>Objectif de la sophro-marche</h3>
                  <h4>
                    Se détendre, se ressourcer au contact des éléments et optimiser votre bien-être !  Lors de cette sophro-marche, vous vous reconnecterez à la nature et à vos sens.
                    Vous ressentirez les bienfaits de cet environnement naturel et boosterez votre énergie !
                    <br><br>Pendant 1h30, nous vous proposons de combiner les bienfaits de la marche et de la sophrologie (relaxation & détente musculaire, sérénité et visualisation positive ).
                    <br>A chaque étape de cette promenade régénérante, vous découvrirez des exercices de sophrologie faciles à pratiquer et solliciterez vos 5 sens pour renouer avec la nature comme par exemple :
                  </h4>
                  <h4>
                    - Respirer et sentir le parfum des mousses, des champignons, des résines et des fleurs,
                    <br>- Écouter le bruissement des feuilles ou les chants des oiseaux,
                    <br>- Toucher l’écorce des arbres ou ressentir l’air pur caresser votre visage,
                    <br>- Découvrir et admirer une clairière,
                    <br>- Cueillir et goûter des fraises des bois ou quelques mûres…
                  </h4>
                  <h4>
                    Un total dépaysement pour vous rendre votre bonne humeur et retrouver toute votre belle énergie à l’issue de cette balade.
                  </h4>
                </div>
              </section>
            </v-card>
          </v-col>
          <v-col cols="12" md="12" lg="4" xl="4" class="reduce-opacity">
            <v-card elevation="10" height="auto" class="v-card-resize" shaped>
              <section>
                <div class="event">
                  <h1>Développement personnel<br>Atelier de communication positive et bienveillante pour optimiser vos échanges relationnels</h1>
                  <h4>1 jour <i>(tarif : Nous consulter)</i></h4>
                  <h5><i>maximum de 20 personnes</i></h5>
                  <h3>Introduction à la communication positive et bienveillante</h3>
                  <h4>
                    Cet atelier de développement personnel se déroule en groupe pour développer des relations constructives au sein de votre service ou de votre entreprise.
                    À l’aide de jeux interactifs autour des 5 sens , les participants s’entraînent à créer du lien, à partager des ressentis, à repérer des moyens de désamorcer des conflits sans être impactées par des émotions trop intenses.
                    Les membres du groupe découvrent également les conséquences de l’interprétation et s’exercent à améliorer leurs échanges relationnels en communication harmonieuse et positive.
                  </h4>
                  <h3>Objectif</h3>
                  <h4>
                    - Identifier les modes de communication inappropriés
                    <br>- Les besoins insatisfaits
                    <br>- Les conflits sous-jacents
                  </h4>
                  <h2>Valeur ajoutée</h2>
                  <h4>
                    Grâce à des jeux interactifs en binôme ou en groupes (jeux de cartes, ateliers autour des 5 sens)
                    les participants se sentent en confiance pour comprendre et savoir exprimer leurs ressentis dans le but d’identifier collectivement
                    les solutions les mieux adaptées à l’amélioration des relations interpersonnelles.
                  </h4>
                  <h2>Nombre de participants :</h2>
                  <h4>
                    Module 1 :  1 journée : créer du lien , partager, développer une communication positive
                    <br>Module 2 :  1 journée : pratiquer la communication positive et bienveillante, découvrir un parfum d’ambiance qui reprenne toutes les valeurs de l’équipe ou de l’entreprise.
                  </h4>
                </div>
              </section>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </section>
  </div>
</template>
<script>
import VueObserveVisibility from 'vue-observe-visibility';

export default {
  name: 'cfe',
  data() {
    return {
      formationVisibility: false,
    }
  },
  methods: {
    formationHandler() {
      this.formationVisibility = true;
    },
  },
  components: VueObserveVisibility,
}

</script>

<style>
@import "cfe.scss";
</style>
