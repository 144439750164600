<template>
  <div>
    <section id="blog">
      <div class="text-center">
        <v-dialog v-model="lockAvis.dialog" width="500">
          <template v-slot:activator="{ on, attrs }">
            <div class="title center">
              <h1 class="title-section" style="cursor: default" v-bind="attrs" v-on="on">Témoignages</h1><v-icon v-if="lockAvis.state" @click="avisModif = !avisModif">mode_edit</v-icon>
              <hr>
            </div>
          </template>

          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Mot de passe
            </v-card-title>

            <!--                <input type="password" v-model="lockAvis.testedPass">-->
            <v-text-field
                label="mot de passe"
                v-model="lockAvis.testedPass"
                :rules="lockAvis.rule"
            ></v-text-field>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="unlockAvis()">
                Valider
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div class="title center">
        <p>« La nature, qui ne nous a donné qu’un seul organe pour la parole, nous en a donné deux pour l’ouïe, afin de nous apprendre qu’il faut plus écouter que parler »</p>
      </div>
      <v-form v-model="valid" v-if="avisModif" ref="myform">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-text-field v-model="updateAvis.title" :rules="titleRules" :counter="20" label="Titre" required></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="updateAvis.content" :rules="textRules" :counter="150" label="Texte" required></v-text-field>
            </v-col>
            <v-col>
              <v-file-input v-model="uploadImage" truncate-length="15" accept="image/*" label="Charger une image" show-size counter></v-file-input>
            </v-col>
          </v-row>
        </v-container>
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="updateOneAvis" style="background-color: #22bb12;">
          Valider les modifications
        </v-btn>
        <v-btn :disabled="!valid" color="success" class="mr-4" @click="newAvis" style="background-color: #22bb12;">
          Nouvel élément
        </v-btn>
      </v-form>
      <div id="temoignage" class="container">
        <vue-horizontal-list :items="cinqsens">
          <template v-slot:default="{item}">
            <div class="item">
              <div class="post-preview">
                <div class="preview-post-thumbnail">
                  <v-img :src="item.image" alt="Le Goût" title="Le Goût" style="max-height: 180px; background-size: cover;"></v-img>
                </div>
                <div class="preview-body" :ref="item.title">
                  <h4 class="post-category">{{ item.title }}
                    <v-icon v-if="avisModif" @click="updateForm(item)">mode_edit</v-icon>
                    <v-icon v-if="avisModif" @click="removeAvis(item.id)">delete</v-icon>
                  </h4>
                  <hr>
                  <p>{{ item.content }}</p>
                </div>
              </div>
            </div>
          </template>
        </vue-horizontal-list>
      </div>
    </section>
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <template>
          <v-btn color="red lighten-2">
            Mentions légales
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="headline grey lighten-2">
            Mentions légales
          </v-card-title>

          <v-card-text>
            <u>Identité et Propriétaire du site</u>
            <br>Des chiffres et des étoiles - des_chiffres_et_des_etoiles.fr

            <br>
            <br>Société VIC 23 CONSULTANT
            <br>SARL au capital de 30 000 €
            <br>Siège social : 51, Boulevard Henri Sellier - 92150 Suresnes
            <br>Tel. +33 (0)1 42 04 93 24
            <br>deschiffresetdesetoiles@gmail.com

            <br>
            <br>Numéros d'identification
            <br>R.C.S. Nanterre 403 189 384 || TVA FR 86403189384

            <br>
            <br>Publication du site
            <br>Directeur de publication : Laurence Rivalain
            <br>Responsable de rédaction : Laurence Rivalain
            <br>Webmaster : Frédéric Flacelière

            <br><br>

          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="dialog = false">
              Retour
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>


<script>

import { ToastProgrammatic as Toast } from 'buefy';
import api from '@/api';

export default {
  name: 'contactAvis',
  data() {
    return {
      lockAvis: {
        state: false,
        pass: 'fZh45LR',
        testedPass: '',
        dialog: false,
        rule: [ () => this.lockAvis.pass === this.lockAvis.testedPass || 'Mauvais mot de passe'
        ],
      },
      window: {
        width: 0,
        height: 0
      },
      cinqsens: [],
      updateAvis:
          {
            id: 0,
            title:"",
            image: "",
            content:"",
          },
      contactVisibility: false,
      valid: false,
      uploadImage: null,
      titleRules: [
        v => !!v || 'Champs requis',
        v => v != undefined && v.length <= 20 || 'Le titre ne doit pas dépasser les 20 caractères',
      ],
      textRules: [
        v => !!v || 'Champs requis',
        v => v != undefined && v.length <= 150 || 'Le texte ne doit pas dépasser les 150 caractères',
      ],
      imgRules: [
        v => !!v || 'Champs requis',
        v => /.+\.(jpg)$/.test(v) || /.+\.png/.test(v) || 'formats autorisés : jpg, png',
        v => v != undefined && v.length <= 30 || 'Limite de 30 caractères',
      ],
      avisModifadmin: false,
      avisModif: false,
      dialog: false,
    }
  },
  methods: {
    contactHandler() {
      this.contactVisibility = true;
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    updateForm(item) {
      this.updateAvis =
          {
            id: item.id,
            title: item.title,
            image: item.image,
            content: item.content,
          };
    },
    unlockAvis() {
      if (this.lockAvis.testedPass === this.lockAvis.pass) {
        this.lockAvis.state = true;
      } else {
        this.lockAvis.state = false;
      }
      this.lockAvis.dialog = false;
    },
    updateOneAvis () {
      try {
        let formData = new FormData();
        // formData.append("id", this.updateAvis.id);
        formData.append('title', this.updateAvis.title);
        formData.append('content', this.updateAvis.content);
        formData.append('pass', 'passuploadcontactavis');
        api.put('/api/avis', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'id': this.updateAvis.id,
          }
        })
            .then(() => {
                  // console.log(response);
                  this.getAvis();
                  Toast.open({
                    message: 'Élément modifié',
                    type: 'is-succes',
                  });
                  // this.$refs.myform.reset();
                }
            );
      } catch (e) {
        Toast.open({
          message: 'Chemin vers l\'image invalide',
          type: 'is-succes',
        });
      }
    },
    newAvis () {
      try {
        let formData = new FormData();
        formData.append("image", this.uploadImage);
        formData.append('title', this.updateAvis.title);
        formData.append('content', this.updateAvis.content);
        formData.append('pass', 'passuploadcontactavis');
        api.post('/api/avis', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
            .then(() => {
                  // console.log(response);
                  this.getAvis();
                  Toast.open({
                    message: 'Élément ajouté',
                    type: 'is-succes',
                  });
                }
            );
      } catch (e) {
        Toast.open({
          message: 'Chemin vers l\'image invalide',
          type: 'is-succes',
        });
      }
    },
    removeAvis (idToRemove) {
      // console.log(idToRemove);
      api.delete('/api/avis', {
        headers: {
          'id': idToRemove
        }
      })
          .then(response => {
            console.log(response);
            this.getAvis();
          });
    },
    getAvis() {
      api.get('/api/avis')
          .then((response) => {
                // console.log(response);
                this.cinqsens = [];
                for (const arg of response.data.data) {
                  this.cinqsens.push(
                      {
                        id: arg.idavis,
                        title: arg.name,
                        imageTitle: arg.img,
                        image: `data:image/png;base64, ${arg.bitImage}`,
                        realImage: atob(arg.bitImage),
                        content: arg.comment,
                      }
                  );
                }
              }
          );
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    this.getAvis();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
}

</script>
