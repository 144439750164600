<template>
<div id="footer">
<!--  <img src="@/assets/logoEiffage-blanc.png">-->
<!--  <div class="footer-infos">-->
<!--    <p>Powered by: DSI 2020</p>-->
<!--  </div>-->
</div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
/*#footer {*/
/*  background-color: darkgrey;*/
/*  height: 60px;*/
/*  display: flex;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  position: fixed; //fixed : Le maintenir tjrs affiché en bas du navigateur*/
/*  bottom: 0;*/
/*  width: 100%;*/
/*  .footer-infos {*/
/*    margin-right: 18px;*/
/*    color: white;*/
/*  }*/
/*}*/
</style>
