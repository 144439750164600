<template>
  <div>
<!--    <vs-navbar v-model="activeItem" class="nabarx" style="position:fixed" color="rgba(255,255,250,0.92)" active-text-color="#FE02BB">-->
<!--      <div slot="title">-->
<!--        <vs-navbar-title>-->
<!--          <div><a href="index.html"><img src="@/assets/images/logos/logo_rose_tr.png" class="logo-navbar"></a></div>-->
<!--        </vs-navbar-title>-->
<!--      </div>-->
<!--      <vs-navbar-item index="0">-->
<!--        <a href="#">Accueil : {{ navBarChoice }} ]</a>-->
<!--      </vs-navbar-item>-->
<!--      <vs-navbar-item index="1">-->
<!--        <a href="#who-we-are">Qui sommes nous ?</a>-->
<!--      </vs-navbar-item>-->
<!--&lt;!&ndash;      <vs-navbar-item index="2">&ndash;&gt;-->
<!--&lt;!&ndash;        <a href="#team">L'équipe</a>&ndash;&gt;-->
<!--&lt;!&ndash;      </vs-navbar-item>&ndash;&gt;-->
<!--&lt;!&ndash;      <vs-navbar-item index="3">&ndash;&gt;-->
<!--&lt;!&ndash;        <a href="#consultation">Consultation</a>&ndash;&gt;-->
<!--&lt;!&ndash;      </vs-navbar-item>&ndash;&gt;-->
<!--      <vs-navbar-item index="2">-->
<!--        <a href="#portraitpb">Portrait porte-bonheur</a>-->
<!--      </vs-navbar-item>-->
<!--      <vs-navbar-item index="3">-->
<!--        <a href="#communicationbv">Communication bienveillante</a>-->
<!--      </vs-navbar-item>-->
<!--      <vs-navbar-item index="4">-->
<!--        <a href="#formation">Formation</a>-->
<!--      </vs-navbar-item>-->
<!--&lt;!&ndash;      <vs-navbar-item index="5">&ndash;&gt;-->
<!--&lt;!&ndash;        <a href="#event">Événementiel</a>&ndash;&gt;-->
<!--&lt;!&ndash;      </vs-navbar-item>&ndash;&gt;-->
<!--      <vs-navbar-item index="5">-->
<!--        <a href="#tarif-consultation">Tarifs et consultations</a>-->
<!--      </vs-navbar-item>-->
<!--      <vs-navbar-item index="6">-->
<!--        <a href="#contact">Contact</a>-->
<!--      </vs-navbar-item>-->
<!--      <vs-navbar-item index="7">-->
<!--        <a href="#temoignage">Témoignages</a>-->
<!--      </vs-navbar-item>-->
<!--    </vs-navbar>-->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'NavBar',
  data() {
    return {
      activeItem: 0,
      // displayServies: false,
    };
  },
  computed: {
    ...mapState('user', ['loggedIn']),
  },
  // beforeMount() {
  //   if (document.body.clientWidth <= 500) {
  //     console.log('changement de la taille à 5');
  //     this.displayServies = true;
  //   } else {
  //     this.displayServies = false;
  //   }
  // },
  methods: {
    ...mapActions([
      'updateNavBarChoice'
    ]),
    // backArbo() {
    //   history.back();
    // },
    logout() {
      this.logUserOut();
      // this.$router.push('/login').catch(err => console.log(err));
      this.$router.push('/').catch(err => console.log(err));
    },
  },
};
</script>

<style>
.logo-navbar {
  height: 75px;
  width: auto;
}
</style>
